import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export const query = graphql`
  {
    strapiSocialMediaLinksFooter {
      title
      footerSocialMediaLinks {
        iconClass
        name
        link
      }
    }
  }
`

export const FooterSocialLinks = () => {

	const data = useStaticQuery(query)
  const {
    strapiSocialMediaLinksFooter: { footerSocialMediaLinks: links },
  } = data

  return (
    <ul className="social-link">
			{links.map(({ iconClass, link}) => (
				<li key={`link-icon-${iconClass}`}>
					<a
						href={link}
						className="d-block"
						target="_blank"
						rel="noreferrer"
					>
						<i className={iconClass}></i>
					</a>
				</li>
			))}
    </ul>
  )
}

export default FooterSocialLinks
