import React from 'react'
import { Helmet } from 'react-helmet'
import * as _ from 'lodash'

const Seo = ({ metaTags, twitterCard }) => {
  const OgMeta = metaTags
  const twitterMeta = metaTags

  return (
    <div>
      <Helmet>
        <title>{metaTags.title}</title>
        {Object.keys(metaTags).map((key, index) =>
          _.get(metaTags, key) ? (
            <meta name={key} content={metaTags[key]} key={`meta-${index}`} />
          ) : (
            ''
          )
        )}
        {Object.keys(OgMeta).map((key, index) =>
          _.get(OgMeta, key) ? (
            <meta
              property={`og:${key}`}
              content={OgMeta[key]}
              key={`meta-${key}-${index}`}
            />
          ) : (
            ''
          )
        )}

        {twitterCard ? (
          <meta name="twitter:card" content={twitterCard.card} />
        ) : (
          ''
        )}

        {Object.keys(twitterMeta).map((key, index) =>
          _.get(twitterMeta, key) ? (
            <meta
              name={`twitter:${key}`}
              content={twitterMeta[key]}
              key={`twitter-${key}-${index}`}
            />
          ) : (
            ''
          )
        )}
      </Helmet>
    </div>
  )
}

export default Seo
