import React, { useState } from 'react'
import { RecoilRoot } from 'recoil'
import GoTop from './GoTop'
import Seo from './Seo'
import { graphql, Link, useStaticQuery } from 'gatsby'
import * as _ from 'lodash'
import CookieConsent from 'react-cookie-consent'

const query = graphql`
  {
    strapiSeos(slug: { eq: "home" }) {
      Meta {
        title
        description
        keywords
      }
      TwitterMeta {
        card
      }
    }
  }
`

const Layout = ({ children, metaTags = null, twitterCard = null }) => {
  const data = useStaticQuery(query)
  if (!metaTags) {
    const {
      strapiSeos: { Meta: temp },
    } = data
    metaTags = temp
  }

  if (!twitterCard) {
    const {
      strapiSeos: { TwitterMeta: temp },
    } = data
    twitterCard = temp
  }

  return (
    <RecoilRoot>
      <Seo metaTags={metaTags} twitterCard={twitterCard} />
      {children}
      <GoTop scrollStepInPx="100" delayInMs="10.50" />
      <CookieConsent
        disableStyles
        buttonText="I Agree"
        buttonClasses="cookieBtn mb-5 py-2 col-12 col-lg-3 mt-3 float-right"
        containerClasses="cookieContainer fixed-bottom col-lg-5 col-11 mb-4 px-md-5 px-3 pt-md-6 pt-4"
        contentClasses="text-center text-lg-left"
      >
        <div className="text-left col-lg-7">
          We use cookies to ensure you get the best browsing experience. By
          continued use, you agree to our privacy policy and accept our use of
          such cookies. For further information, click Find out more.
        </div>
        <div className="mr-5 pr-4">
          <Link
            className="cookiePrivacyButton mt-lg-1 mt-5 pt-3 mr-6"
            type="button"
            to="/privacy-policy"
          >
            Find out more
          </Link>
        </div>
      </CookieConsent>
    </RecoilRoot>
  )
}

export default Layout
