import React from 'react'
import { Link } from 'gatsby'
import logo from '../../assets/images/brewedlogic-logo-horizontal-blue.svg'
import footerMap from '../../assets/images/footer-map.png'
import FooterSocialLinks from './FooterSocialLinks'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>
              <p>
                Offshore software development company. <br />
                We deliver web and mobile apps tailored to your needs.
              </p>

              <FooterSocialLinks />
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              <h3>Explore</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/how-we-work">How We Work</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/work-with-us">Work With Us</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <h3>Address</h3>

              <ul className="footer-contact-info">
                <li>
                  <i className="bx bx-map"></i>
                  Unit 2201, 88 Corporate Centre, Valero corner Sedeno Streets,
                  Salcedo Village,
                  <br />
                  Makati City, Philippines
                </li>
                <li>
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:hello@brewedlogic.com">
                    hello@brewedlogic.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                Copyright @{currentYear} <strong>BrewedLogic Inc.</strong>
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src={footerMap} alt="footer-logo" />
      </div>
    </footer>
  )
}

export default Footer
